<template>
  <div>
    <el-dialog :visible.sync="visible" :title="title" width="40%">
      <el-form
        ref="addForm"
        :model="addForm"
        :rules="rules"
        label-position="right"
        label-width="100px"
      >
        <el-form-item label="轮播图名称:" prop="title">
          <el-input v-model="addForm.title" />
        </el-form-item>
        <el-form-item label="轮播图图片:" prop="picture">
          <el-upload
            v-model="addForm.picture"
            class="avatar-uploader"
            :action="action"
            :data="uploadData"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <el-input v-model="addForm.picture" style="display: none" />
            <img v-if="addForm.picture" :src="addForm.picture" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon" />
          </el-upload>
        </el-form-item>
        <el-form-item label="资讯ID:" prop="newsId">
          <el-input v-model="addForm.newsId" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取 消</el-button>
        <el-button type="primary" @click="addEditSubmit('addForm')"
          >确 认</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { addSlideShow, updateSlideShow } from '@/utils/api.js'
import { host } from '@/config'
import httpRequest from '@/utils/httpRequest.js'
export default {
  data() {
    return {
      visible: false,
      title: '添加轮播图',
      addForm: {
        title: '',
        picture: '',
        newsId: ''
      },
      rules: {
        title: {
          required: true,
          message: '请输入轮播图标题',
          trigger: 'blur'
        },
        picture: {
          required: true,
          message: '请上传轮播图图片',
          trigger: 'change'
        },
        newsId: {
          required: true,
          message: '请输入资讯ID',
          trigger: 'blur'
        }
      },
      action: httpRequest.api('/file/upload'),
      uploadData: {
        type: '2',
        imgModel: '6'
      }
    }
  },
  methods: {
    open(carouselInfo) {
      if (carouselInfo.id) {
        this.title = '编辑轮播图'
        this.addForm = carouselInfo
      } else {
        this.addForm = {}
        this.title = '添加轮播图'
      }
      this.visible = true
    },
    async addEditSubmit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (this.title === '添加轮播图') {
            const res = await addSlideShow(this.addForm)
            this.visible = false
            if (res.code === 200) {
              this.addForm = {}
              this.$message({
                message: '添加成功',
                type: 'success'
              })
            } else {
              this.$message.error(res.message)
            }
          } else {
            const res = await updateSlideShow(this.addForm)
            this.visible = false
            if (res.code === 200) {
              this.addForm = {}
              this.$message({
                message: '编辑成功',
                type: 'success'
              })
            } else {
              this.$message.error(res.message)
            }
          }
          this.$emit('addEditSuccess')
        }
      })
    },
    handleAvatarSuccess(res) {
      if (res.code === 200) {
        this.$set(this.addForm, 'picture', `${host}/file/browse/${res.data}`)
      } else {
        this.$message.error(res.message)
      }
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 4
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 4MB!')
      }
      return isLt2M
    }
  }
}
</script>

<style lang="less" scoped>
.el-input {
  width: 60%;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
}
.avatar {
  width: 40px;
  height: 40px;
  display: block;
}
.dialog-footer {
  text-align: center;
  margin-top: 20px;
}
</style>
