<template>
  <div>
    <el-card>
      <div class="carousel-flex-box">
        <el-form inline>
          <el-form-item label="轮播标题:">
            <el-input v-model="form.title" size="small" />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="small" @click="getDataList">查 询</el-button>
          </el-form-item>
          <el-form-item>
            <el-button size="small" @click="resetForm">重 置</el-button>
          </el-form-item>
        </el-form>
        <div><el-button type="primary" @click="addEditCarousel">添加轮播图</el-button></div>
      </div>
      <el-table
        v-loading="dataListLoading"
        :data="carouselList"
        style="width: 100%;padding: 8px 0 ! important;"
        :header-cell-style="{background:'#F7F7F7',color:'#1E1E1E'}"
      >
        <el-table-column label="轮播标题" prop="title" />
        <el-table-column label="创建时间">
          <template slot-scope="{row}">
            <span>{{ row.createTime | F_formatDate }}</span>
          </template>
        </el-table-column>
        <el-table-column label="跳转资讯ID" prop="newsId" />
        <el-table-column label="状态">
          <template slot-scope="{row}">
            <el-switch v-model="row.state" :active-value="1" :inactive-value="0" @change="changeState(row)" />
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="{row}">
            <el-button type="text" size="small" @click="viewCarouselDetail(row)">查看</el-button>
            <el-button type="text" size="small" @click="addEditCarousel(row)">编辑</el-button>
            <el-button type="text" size="small" @click="deleteCarousel(row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="current"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="size"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
        style="padding:20px;"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </el-card>
    <!-- 查看 -->
    <el-dialog :visible.sync="visible" title="查看" width="40%">
      <div class="img-box">
        <img :src="carouselDetail.picture">
        <div class="img-title">{{ carouselDetail.title }}</div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="visible=false">关 闭</el-button>
      </div>
    </el-dialog>
    <!-- 添加/编辑轮播图 -->
    <add-edit-carousel ref="addEditCarousel" @addEditSuccess="getDataList" />
  </div>
</template>

<script>
import { slideShowList, deleteSlideShow, updateSlideShow } from '@/utils/api.js'
import AddEditCarousel from './components/AddEditCarousel'
export default {
  components: { AddEditCarousel },
  data() {
    return {
      form: {},
      carouselList: [],
      dataListLoading: false,
      visible: false,
      current: 1, // 当前页
      size: 10, // 每页条数
      total: null, // 总条数
      carouselDetail: {}
    }
  },
  mounted() {
    this.getDataList()
  },
  methods: {
    // 获取轮播图列表
    async getDataList() {
      this.dataListLoading = true
      const res = await slideShowList(this.form, this.current, this.size)
      this.dataListLoading = false
      if (res.code === 200) {
        this.carouselList = res.data.records
        this.current = res.data.current
        this.total = res.data.total
      } else {
        this.$message.error(res.message)
      }
    },
    resetForm() {
      this.form = {}
      this.getDataList()
    },
    addEditCarousel(carouselInfo) {
      this.$refs.addEditCarousel.open(carouselInfo)
    },
    // 切换轮播图状态
    async changeState(val) {
      const res = await updateSlideShow(val)
      if (res.code === 200) {
        this.$message({
          message: '切换状态成功',
          type: 'success'
        })
      } else {
        this.$message.error(res.message)
      }
      this.getDataList()
    },
    viewCarouselDetail(val) {
      this.carouselDetail = val
      this.visible = true
    },
    deleteCarousel(id) {
      this.$confirm('是否确认删除?', '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteSlideShow(id).then(res => {
          if (res.code === 200) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.getDataList()
          } else {
            this.$message.error(res.message)
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 每页数
    handleSizeChange(val) {
      this.size = val
      this.current = 1
      this.getDataList()
    },
    // 当前页
    handleCurrentChange(val) {
      this.current = val
      this.getDataList()
    }
  }
}
</script>

<style lang="less" scoped>
.carousel-flex-box {
  display: flex;
  justify-content: space-between;
}
.img-box {
  margin: 0 auto;
  width: 80%;
  text-align: center;
  img {
    width: 100%;
  }
  .img-title {
    font-size: 16px;
    margin-top: 10px;
  }
}
.dialog-footer {
  text-align: center;
  margin-top: 20px ;
}
</style>
